import React from "react";
import './Header.css'

const Header = () => {
return (
    <>
    <div className="header">
       <div className="header_name">
      <h1>Ashutosh Mishra</h1>
    </div>
    <div className="header_contact">
    <h3>9462209506</h3>
    </div>
    </div>
    </>
)
}

export default Header;