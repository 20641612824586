import React from "react";
import './Home.css';
import dp from '../image/am2.png';
import { useState } from 'react';
const Home = () => {

  return (
    <>
      <div className="container home">

        <div className="paragraph">

          <img className="img_style" src={dp} style={{ borderRadius: '50%' }} />

        </div>
        <div className="paragraph" style={{ textAlign: 'center' }}>

          <p className="responsive-text">  HI, I AM A SOFTWARE ENGINEER</p>
          <div className="typewriter" style={{ textAlign: 'center', display: 'flex' }}>
            <h1 className="responsive-text">QUALITY ANALYST</h1>
          </div>

          <p className="responsive-text">based in Gurugram, Haryana</p>
        </div>


      </div >
    </>
  )
}

export default Home;