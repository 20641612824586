import React from "react";

const WorkCard = (props) => {
    return (
        <>
            <div style={{ width: '100%' }}>
                <img src={props.imagePath} style={{ height: '50px' }} />
                <h2 className="responsive-textService">{props.title}</h2>
                <p className="responsive-textService">{props.description}</p>
            </div>
        </>
    )
}
export default WorkCard;  