import React from "react";
import WorkCard from "../WorkCard/WorkCard";
import qa from '../image/test.png';
import automation from '../image/automation.png';
import performance from '../image/speedometer.png';

const Services = () => {
    return (
        <>
            <div className="" style={{ background: '#f8f9fb', height: 'auto', textAlign: 'center' }}>
                <div>
                    <span className="about_span">What I Do ?</span>
                </div>
                <br></br>
                <div style={{ textAlign: 'center', marginTop: '7%' }} className="responsive-text">
                    <h1 className="responsive-text">How I can help your next project</h1>
                </div>
                <div className="containerAbout" >
                    <div className="" style={{ visibility: 'visible', display: 'flex' }}>
                        <WorkCard imagePath={qa} title={"Software Engineering"} description={"In software engineering, I can assist you by offering expert insights, code reviews, algorithm optimization, and troubleshooting. Whether you need guidance on architectural decisions, best coding practices, or help with debugging, I'm here to support your projects."} />
                    </div>
                    <div className="" style={{ visibility: 'visible', display: 'flex' }}>
                        <WorkCard imagePath={automation} title={"Automation Testing"} description={"In the field of software automation engineering, I can be a guiding force in streamlining and enhancing your development processes. With expertise in scripting languages (Java with Selenium, Appium and Rest Assured), testing frameworks, and CI/CD pipelines, I can help create efficient and reliable automated workflows. "} />
                    </div>
                    <div className="" style={{ visibility: 'visible', display: 'flex' }}>
                        <WorkCard imagePath={performance} title={"Performance Testing"} description={"Tool: Jmeter | In the realm of performance testing, I offer valuable expertise in optimizing software applications for speed, scalability, and responsiveness. Through meticulous test planning, load testing, and analysis, I can identify bottlenecks, ensure peak performance, and enhance user experience."} />
                    </div>
                </div>

                <div className="containerAbout" style={{ marginTop: '5%' }} >
                    <div className="" style={{ visibility: 'visible', display: 'flex' }}>
                        <WorkCard imagePath={qa} title={"Manual Testing"} description={"I bring expertise in meticulously assessing software products for functionality, usability, and quality. With a keen eye for detail, I design comprehensive test plans, execute thorough test cases, and report accurate findings. I collaborate closely with development teams, ensuring open communication and swift issue resolution."} />
                    </div>
                    <div className="" style={{ visibility: 'visible', display: 'flex' }}>
                        <WorkCard imagePath={automation} title={"Load Testing"} description={"In the domain of load testing, I offer specialized skills in evaluating software applications under varying workloads to ensure optimal performance and stability. Through strategic test design and execution, I simulate realistic user scenarios to assess how the software handles heavy traffic and usage. I utilize advanced tools and techniques to measure response times, identify performance bottlenecks, and recommend optimizations."} />
                    </div>
                    <div className="" style={{ visibility: 'visible', display: 'flex' }}>
                        <WorkCard imagePath={performance} title={"Requirement Analysis"} description={" I bring expertise in dissecting project specifications to extract crucial details that drive software development. With a meticulous approach, I decipher client needs, business objectives, and user expectations. Through effective communication, I collaborate with stakeholders to ensure a clear understanding of project goals. By translating complex requirements into actionable plans, I guide development teams toward successful outcomes."} />
                    </div>
                </div>





            </div>
        </>
    )
}

export default Services;