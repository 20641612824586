import React from "react";
import './About.css';

const About = () => {

    return (
        <>
            <span className="about_span">About Me</span>

            <br></br><br></br>
            <h2 className="am" style={{ border: 'none' }}>Know Me More</h2>


            <div className="containerAbout" style={{ background: 'white', margin: '1% 6% 0% 1%' }}>
                <div className="paragraph" style={{ textAlign: 'justify', width: '100%' }}>


                    <br></br>
                    <p className="description">Hi, I'm <span className="am">Ashutosh Mishra</span>    </p>
                    <p className="description responsive-text">I am a versatile software testing professional who combines manual, automation, and performance testing expertise with strong leadership skills. My dedication to quality assurance and my ability to manage complex testing projects make me an invaluable asset to any software development team.</p>
                </div>
                <div className="paragraph" style={{ textAlign: 'justify' }}>
                    <div class="circle" >
                        <span class="number">10</span>
                    </div>
                    <div>
                        <p style={{ fontSize: '30px', textAlign: 'center' }}>Years of <span style={{ fontWeight: '700' }}>Experience</span></p>
                    </div>
                    <br>
                    </br>

                </div>
            </div>
            <br>
            </br>
            <div className="containerAbout" >
                <div class="col-6 col-lg-3 wow fadeInUp" style={{ visibility: 'visible', marginLeft: '2%' }}><p style={{ textAlign: 'center' }}>Name:</p><p style={{ fontWeight: '600', color: '#212529' }}>Ashutosh Mishra</p></div>
                <div class="col-6 col-lg-3 wow fadeInUp" style={{ visibility: 'visible' }}><p style={{ textAlign: 'center' }}>Email:</p><p style={{ fontWeight: '600' }}>i@ashutoshmishra.com</p></div>
                <div class="col-6 col-lg-3 wow fadeInUp" style={{ visibility: 'visible' }}><p style={{ textAlign: 'center' }}>Date Of Birth:</p><p style={{ fontWeight: '600' }}>25th October</p></div>
                <div class="col-6 col-lg-3 wow fadeInUp" style={{ visibility: 'visible', marginRight: '2%' }}><p style={{ textAlign: 'center' }}>From:</p><p style={{ fontWeight: '600' }}>Nohar, India</p></div>
            </div>


        </>
    )
}
export default About;