import React from "react";

const WorkEduCard = (prop) => {
    return (
        <>
            <div style={{


            }}>
                <h2>{prop.title}</h2>
                <p>{prop.coll_comp}</p>
                <p>{prop.detail}</p>
            </div>
        </>
    )
}
export default WorkEduCard;