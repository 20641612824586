import React from "react";
import WorkCard from "../WorkCard/WorkCard";
import WorkEduCard from "./WorkEduCard";
const Work = () => {
    return (

        <>
            <div>
                <span className="about_span">Experience & Education</span>
            </div>
            <div style={{ background: '#f8f9fb', height: 'auto', textAlign: 'center', marginTop: '70px' }}>

                <div className="containerAbout" style={{ height: 'auto', background: 'white' }}>

                    <div className="paragraph" style={{
                        textAlign: 'justify', borderLeftStyle: 'groove',
                        borderLeftColor: 'yellow'
                    }}>
                        <h3 style={{ textAlign: 'center' }}> Work Experience</h3>
                        <ul class="list-view" style={{}}>
                            <li> <WorkEduCard title={'Airtel Payments Bank, Gurgaon'} coll_comp={'Sr. Manager'} detail={'Managing a team | Getting Requirements from Product team | Create Test Plans and Distribute work among team members | Responsible for Automation framework to automate web, mobile (iOS and Android) and Windows applications | End to End testing.'} /></li>
                            <li> <WorkEduCard title={'Xebia IT Architech, Gurgaon'} coll_comp={'Sr. Consultant'} detail={'Creating a framework to automate all web, mobile (iOS and Android) and Windows applications. | Creating UAT(s). | Developing Test cases.'} /></li>
                            <li> <WorkEduCard title={'DXC Technology, Noida'} coll_comp={'P1 Test Engineer'} detail={'Create/Enhance the framework. • Add new functionalities.'} /></li>
                            <li> <WorkEduCard title={'ANR Software Pvt Ltd, Noida'} coll_comp={'Jr. Test Programmer'} detail={'Create a framework to run the test scripts (Selenium and Appium). | Used POM modal for AWS specific automation script. | Executed test scripts.'} /></li>
                        </ul>
                    </div>
                    <div className="paragraph" style={{
                        textAlign: 'justify', borderLeftStyle: 'groove',
                        borderLeftColor: 'yellow'
                    }}>
                        <h3 style={{ textAlign: 'center' }}>Education</h3>
                        <ul class="list-view" style={{}}>
                            <li> <WorkEduCard title={'QA Diploma'} coll_comp={'Seed Infotech, Jaipur'} detail={' 2013 - 2014'} /></li>
                            <li> <WorkEduCard title={'B.Tech'} coll_comp={'Global Institute Of Technology, Jaipur'} detail={'2009 - 2013'} /></li>
                            <li> <WorkEduCard title={'12th'} coll_comp={'Govt Sr Sec School, Nohar'} detail={'2007 - 2009'} /></li>
                            <li> <WorkEduCard title={'10th'} coll_comp={'Chaudhary Public School, Nohar'} detail={'2006 - 2007'} /></li>

                        </ul>
                    </div>
                </div>






            </div >


        </>
    )
}
export default Work;
