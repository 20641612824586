import logo from './logo.svg';
import './App.css';
import Home from './Home/Home';
import Header from './Header/Header';
import About from './About/About';
import Services from './Services/Services';
import Work from './Work/Work';
import Contact from './Contact /Contact';

function App() {
  return (
    <div className="App" >
      <Header />
      <Home />
      <About />
      <Services />
      <Work />
      <Contact />
    </div>
  );
}

export default App;
