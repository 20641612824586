import React, { useState } from "react";
import { toast } from "react-toastify";
import './Contact.css';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contact() {
    const initialFormData = {
        name: '',
        email: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);


    const handleChange = event => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async event => {
        event.preventDefault();
        try {

            const response = await fetch('https://ashutoshmishra.com/contactAshutoshMishra.php', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: new URLSearchParams(formData)
                //  body: JSON.stringify(formData)
            });

            toast.success(formData.name + ", Your message has been sent.", { autoClose: 3000 });
            // User have to close it
            setFormData(initialFormData);
            console.log(response)
            if (!response.ok) {
                console.log('Failed to send request');
            }
            console.log('ashutosh mishra');
            const data = await response.text();
            console.log(data); // Display response message
            console.log('ashutosh mishra');
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <div style={{ background: '#eee8aa !important', height: 'auto', textAlign: 'center' }}>
                <div style={{ display: 'flex' }}>
                    <span className="about_span">Contact Me</span>
                </div>

                <br></br>
                <div className="container">
                    {/* <h1 className="responsive-text">How I can help your next project</h1> */}
                    <div className="paragraph" style={{
                        textAlign: 'justify'
                    }}>
                        <h3 style={{ textAlign: 'center' }}> Work Experience</h3>
                        <p className="responsive-text">I enjoy discussing new projects and design challenges. Please share as much info, as possible so we can get the most out of our first catch-up.</p>
                        <h3 className="responsive-text">Get in Touch</h3>
                        <p className="responsive-text">i@ashutoshmishra.com</p>

                    </div>
                    <div className="paragraph" style={{
                        textAlign: 'justify'
                    }}>
                        <h2>Contact Me</h2>
                        <form onSubmit={handleSubmit}>
                            <span>What Is Your Name ?</span> <br></br>
                            <input className="form-control py-1" type="text" name="name" value={formData.name} onChange={handleChange} required />
                            <br></br>
                            <span>Your Email Address:</span> <br></br>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                            <br></br>
                            <span>How Can I Help You ?:</span> <br></br>
                            <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
                            <br></br>  <button className="button" type="submit">Submit</button>
                            <ToastContainer />
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact;